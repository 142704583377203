<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-menu-button color="primary"></ion-menu-button>
        </ion-buttons>
        <ion-title>Badge Virtuale</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content :fullscreen="true">
      <ion-header collapse="condense">
        <ion-toolbar>
          <ion-title size="large">{{ $route.params.id }}</ion-title>
        </ion-toolbar>
      </ion-header>
      <div id="container">
        <strong class="capitalize">{{ $route.params.id }}</strong>  
        <div v-if="attendance.verso==4 &&  attendance.date==today">
          
        </div>
        <div v-else>
          <img src="images\entrata.PNG" @click="entrata()" alt="ENTRATA" v-if="attendance.verso==2 || attendance.verso==0 || attendance.verso==4">
          <img src="images\uscita.PNG" @click="uscita()" alt="USCITA"  v-if="attendance.verso==1 || attendance.verso==3">
        </div>
      </div>
      <div :value="a.id" v-for="a,i in attendances" :key="'attendance-'+i">
          <!--
          <div v-if="a.verso==1">ENTRATA &nbsp;   
            <div id="timbrata"><span id="timbr">{{a.timbr1}}</span></div> 
          </div>
          <div v-if="a.verso==2">USCITA &nbsp;   
            <div id="timbrata"><span id="timbr">{{a.timbr2}}</span></div> 
          </div>
          <div v-if="a.verso==3">ENTRATA &nbsp;   
            <div id="timbrata"><span id="timbr">{{a.timbr3}}</span></div> 
          </div>
          <div v-if="a.verso==4">USCITA &nbsp;   
            <div id="timbrata"><span id="timbr">{{a.timbr4}}</span></div> 
          </div>-->
        <ion-item>
          <div v-if="a.timbr1!=''">ENTRATA &nbsp;
           <span id="timbr">{{a.timbr1}}</span>
          </div>
        </ion-item>
        <ion-item>
          <div v-if="a.timbr2!=''">USCITA &nbsp;
            <span id="timbr">{{a.timbr2}}</span>
          </div>
        </ion-item>
        <ion-item>
          <div v-if="a.timbr3!=''">ENTRATA &nbsp;  
            <span id="timbr">{{a.timbr3}}</span>
          </div>
        </ion-item>
        <ion-item>
          <div v-if="a.timbr4!=''">USCITA &nbsp;
           <span id="timbr">{{a.timbr4}}</span>
          </div>
        </ion-item>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
  import { 
    IonButtons, 
    IonContent, 
    IonHeader, 
    IonItem,
    IonMenuButton, 
    IonPage, 
    IonTitle, 
    IonToolbar,
    toastController 
  } from '@ionic/vue';

  export default {
    name: 'Folder',
    components: {
      IonButtons,
      IonContent,
      IonHeader,
      IonItem,
      IonMenuButton,
      IonPage,
      IonTitle,
      IonToolbar,
  },
  data: function(){
    return{
      attendances:[],
      attendance:{
        user:'',
        date:'',
        time:'',
        verso:'',
      },
      today:'',
    }
  },
  methods:{
    getUser(){
      this.axios.post(this.apiUrl + 'get-user', {}, this.headers)
      //.then(async (response) => {
        //console.log(response)
      //})
    },
    getLastAttendance(){
      this.axios.post(this.apiUrl + 'get-last-attendance', {}, this.headers)
      .then(async (response) => {
          if(response.data.status_code==200){
              this.attendance=response.data.attendance           
              this.today=response.data.today   
          }
      })
    },
    getAttendancesDay(){
      this.axios.post(this.apiUrl + 'get-today-attendances', {}, this.headers)
      .then(async (response) => {
          if(response.data.status_code==200){
              this.attendances=response.data.attendances           
          }
      })
    },
    async entrata(){
      this.getLastAttendance();
      if(this.attendance.verso==1){
        const toast = await toastController
        .create({
            color: 'warning',
            message: 'Dovresti effettuare un\'uscita',
            duration: 2000
        })
        return toast.present();
      }else{
        this.attendance.verso=1;
        this.axios.post(this.apiUrl + 'entrata-attendance', {}, this.headers)
        .then(async (response) => {
          if(response.data.status_code==200){
            this.attendance.user='';
            this.attendance.date='';
            this.attendance.time='';
            const toast = await toastController
            .create({
                color: 'success',
                message: response.data.message,
                duration: 2000
            })
            window.location.reload();
            return toast.present();
          }
        })
      }
    },
    async uscita(){
      this.getLastAttendance();
      if(this.attendance.verso==2){
        const toast = await toastController
        .create({
            color: 'warning',
            message: 'Dovresti effettuare un\'entrata',
            duration: 2000
        })
        return toast.present();
      }else{
        this.attendance.verso=2;
        this.axios.post(this.apiUrl + 'uscita-attendance', {}, this.headers)
        .then(async (response) => {
          if(response.data.status_code==200){
            this.attendance.user='';
            this.attendance.date='';
            this.attendance.time='';
            this.attendance.verso='';
            const toast = await toastController
            .create({
                color: 'success',
                message: response.data.message,
                duration: 2000
            })
            window.location.reload();
            return toast.present();
          }
        })
      }
    }
  },
  ionViewWillEnter: function(){
    this.getUser();
    this.getLastAttendance();
    this.getAttendancesDay();
  }
}
</script>

<style scoped>
  #container {
    min-height:20%;
    margin-top:5%;
    display:flex;
    align-items:center;
    justify-content:center;
    flex-wrap:wrap;
  }
  img{
    cursor:pointer;
  }
  ion-item{
    margin-top:5%;
  }
  #timbrata{
    width:100%;
    float:right;
  }
  #timbr{
    width:80%;
    margin-left:30%;
    float:right;
  }
</style>